<template>
	<el-dialog title="" :visible.sync="createdVisible" width="578px" center :close-on-click-modal="false" @close="resetForm">
		<div slot="title" class="postion">
			<i v-if="!isShowDetail" class="iconfont icon-bianji1"></i>
			<span class="download">{{ isShowDetail ? '查看' : !isShowDetail && ruleForm.id ? '编辑' : '新增' }}课程</span>
		</div>
		<RsForm
			ref="rsForm"
			labelwidth="100px"
			:columns="columns"
			:inline="false"
			:saveBtn="true"
			:resetBtn="true"
			:searchBtn="false"
			label-position="top"
			:rules="isShowDetail ? {} : rules"
			:saveLoading="saveLoading"
			@query="onSave"
			@reset="resetForm"
			:isShowDetail="isShowDetail"
		/>
		<div class="fileList" v-if="isShowDetail">
			<div class="fileList-title clearfix">
				<div class="float-l" :class="{ activeClass: activeName === 1 }" @click="activeName = 1">材料附件</div>
				<div class="float-l" :class="{ activeClass: activeName === 2 }" @click="activeName = 2">视频附件</div>
			</div>
			<el-table :data="activeName == 1 ? fileTableData : videoTableData" :loading="loading" style="width: 100%">
				<el-table-column type="index" width="50" label="序号"> </el-table-column>
				<el-table-column :label="activeName === 1 ? '教材名称' : '视频名称'" show-overflow-tooltip>
					<template slot-scope="scope">
						<div class="textbookName">
							<img :src="activeName === 1 ? fileImg : videoImg" />
							<span>{{ scope.row.name }}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="createTime" label="上传时间" width="160" show-overflow-tooltip> </el-table-column>
				<el-table-column prop="byteCount" label="大小" show-overflow-tooltip>
					<template slot-scope="scope"> {{ scope.row.byteCount > 0 ? (scope.row.byteCount / 1024 / 1024).toFixed(2) : 0 }}MB </template>
				</el-table-column>
				<el-table-column prop="description" label="课时" show-overflow-tooltip> </el-table-column>
			</el-table>
		</div>
		<span slot="footer" class="dialog-footer" v-if="isShowDetail">
			<el-button type="primary" @click="resetForm">确 定</el-button>
		</span>
	</el-dialog>
</template>

<script>
import fileImg from '@/assets/imgs/fileImg.png';
import videoImg from '@/assets/imgs/videoImg.png';
export default {
	components: {
		Upload: () => import('@/components/Upload/index.vue'),
		RsForm: () => import('@/components/RsForm/index.vue'),
	},
	data() {
		return {
			fileImg,
			videoImg,
			createdVisible: false,
			columns: [
				{
					prop: 'name',
					label: '课程名称',
					type: 'input',
					default: '',
				},
				{
					prop: 'contentTypeDictionaryItemId',
					label: '课程类别',
					type: 'select',
					default: null,
					options: [], //接口请求数据后再赋值
					optionsDefault: ['title', 'id'], //[label,value]
				},
				{
					prop: 'trainingSubjectId',
					label: '所属科目',
					type: 'select',
					default: null,
					options: [], //接口请求数据后再赋值
					optionsDefault: ['name', 'id'], //[label,value]
				},
				{
					label: '课程编码',
					type: 'input',
					prop: 'courseCode',
					default: '',
					className: 'w50 mr16',
				},
				{
					label: '课程定价',
					type: 'input',
					prop: 'price',
					maxlength: 11,
					default: '',
					className: 'w50 mr0',
					inputType: 'number',
					slot: '元',
				},
				{
					prop: 'description',
					label: '课程介绍',
					type: 'textarea',
					default: '',
				},
				{
					label: '课程图片',
					type: 'uploadImg',
					prop: 'images',
					maxCount: 6,
					operator: 'zhangl',
					modulePath: 'COURSE',
				},
			], //表单配置项
			rules: {
				name: [{ required: true, message: '请输入', trigger: 'blur' }],
				contentTypeDictionaryItemId: [{ required: true, message: '请选择', trigger: 'change' }],
				trainingSubjectId: [{ required: true, message: '请选择', trigger: 'change' }],
				description: [{ required: true, message: '请输入', trigger: 'blur' }],
				courseCode: [{ required: true, message: '请输入', trigger: 'blur' }],
				price: [{ required: true, message: '请输入', trigger: 'blur' }],
				images: [{ required: true, message: '请上传图片', trigger: 'change' }],
			},
			attachmentInfo: {},
			saveLoading: false,
			activeName: 1,
			loading: false,
			fileTableData: [],
			videoTableData: [],
			ruleForm: {},
		};
	},
	computed: {},
	props: ['isShowDetail'],
	watch: {},
	created() {},
	methods: {
		init(row) {
			this.createdVisible = true;
			this.getCourseType();
			this.getSubjectList();
			this.getAttachment();
			if (row) {
				this.setRuleForm(row);
				this.getFileInfo(row);
				this.$nextTick(() => {
					this.$refs.rsForm.$refs['queryForm'].resetFields();
				});
				return;
			}
			this.$nextTick(() => {
				this.$refs.rsForm.formData = {};
				this.$refs.rsForm.$refs['queryForm'].resetFields();
			});
		},
		// 获取附件信息
		getFileInfo(row) {
			this.$http
				.get(this.api.getCourses + `/${row.id}.json`)
				.then((res) => {
					if (res.data && res.data.success) {
						let data = res.data.collection || {};
						this.fileTableData = [];
						this.videoTableData = [];
						data.forEach((item) => {
							this.fileTableData = item.attachmentMap['DOCUMENT'].map((iter) => {
								return {
									name: iter.title,
									Pid: iter.id,
									...iter,
								};
							});
							this.videoTableData = item.attachmentMap['VIDEO'].map((iter) => {
								return {
									name: iter.title,
									Pid: iter.id,
									...iter,
								};
							});
						});
					}
				})
				.catch((e) => {});
		},
		// 获取课程类别
		getCourseType() {
			this.$http
				.get(this.api.getDictionaryItems + '.json', { params: { dictionaryCode: 'LESSON_CONTENT_TYPE', size: 9999, current: 1 } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.columns[1].options = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取附件组
		getAttachment() {
			this.$http
				.get(this.api.getAttachmentGroups, { params: { subjectTypeCode: 'COURSE' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.attachmentInfo = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取科目列表
		getSubjectList() {
			this.$http
				.get(this.api.getTrainingSubject + '.json', { params: { size: 9999, current: 1 } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.columns[2].options = res.data.collection.filter((item) => item.enabled) || [];
					}
				})
				.catch((e) => {
					this.loading = false;
				});
		},
		// 设置表单数据
		setRuleForm(data) {
			this.ruleForm = JSON.parse(JSON.stringify(data));
			let params = {
				...data,
				contentTypeDictionaryItemId: data.contentTypeDictionaryItem?.id || '',
				price: (data.price / 100).toFixed(2),
				trainingSubjectId: data.trainingSubject ? JSON.parse(JSON.stringify(data.trainingSubject.id)) : null,
				images: data.attachmentMap['PHOTO'].map((item) => {
					return {
						url: item.link,
						uid: item.id,
						title: item.title,
					};
				}),
			};
			this.$nextTick(() => {
				this.$refs.rsForm.formData = JSON.parse(JSON.stringify(params));
			});
		},
		// 保存
		onSave(data) {
			this.saveLoading = true;
			let par = {
				...data,
				price: JSON.parse(data.price) * 100,
				attachmentList: data.images.map((item) => {
					return {
						link: item.url,
						subjectTypeCode: this.attachmentInfo.find((item) => item.code == 'PHOTO').subjectTypeCode,
						title: item.title,
						attachmentGroupId: this.attachmentInfo.find((item) => item.code == 'PHOTO').id,
						mimeType: item.title.split('.')[1],
					};
				}),
			};
			let primoseUrl = this.ruleForm.id
				? this.$http.put(this.api.getCourses + `/${this.ruleForm.id}.json`, par)
				: this.$http.post(this.api.getCourses + '.json', par);
			primoseUrl
				.then((res) => {
					if (res.data && res.data.success) {
						this.$message.success(this.ruleForm.id ? '修改成功' : '添加成功');
						this.$emit('getList');
						this.resetForm();
						this.saveLoading = false;
					}
				})
				.catch((e) => {
					this.saveLoading = false;
				});
		},
		resetForm() {
			this.ruleForm = {};
			this.$refs.rsForm.formData.images = [];
			this.createdVisible = false;
			this.$nextTick(() => {
				this.$refs.rsForm.formData = {};
				this.$refs.rsForm.$refs['queryForm'].resetFields();
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.postion {
	font-size: 18px;
	.icon-bianji1 {
		margin-right: 16px;
		color: #1db9b1;
	}
}
/deep/ .el-form {
	.el-form-item {
		.el-select,
		.el-input {
			width: 100%;
		}
	}

	.el-form-item__label {
		line-height: 14px;
		height: 14px;
		margin-bottom: 8px;
		padding: 0;
	}
}
/deep/ .el-button {
	margin-left: 16px;
}
.fileList {
	&-title {
		margin-bottom: 8px;
		div {
			width: 56px;
			line-height: 14px;
			padding-bottom: 9px;
			color: #999999;
			margin-right: 32px;
			cursor: pointer;
		}
	}
	.activeClass {
		color: #333333;
		border-bottom: 3px solid #333333;
		box-sizing: border-box;
	}
	/deep/ .el-table {
		th.el-table__cell {
			padding: 8px 0;
			> .cell {
				color: #999999;
				font-size: 14px;
				font-weight: normal;
			}
		}
		td.el-table__cell {
			padding: 10px 0;
			border-bottom: none;
			div {
				line-height: 20px;
				height: 20px;
			}
		}
	}
	.textbookName {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		img {
			margin-right: 8px;
			width: 20px;
			height: 20px;
		}
	}
}
</style>